export const callDetailsDefault = {
  calllog_id: null,
  user_id: null,
  contact_id: null,
  callback_id: null,
  callback_no: null,
  call_direction: 0,
  calltype_id: null,
  is_urgent: 0,
  message: null,
  callforuser_id: null,
  parentcalllog_id: null,
  emailonsave: 0,
  groupmember_login_email: null,
  login_email: null,
  contact_full_name: null,
};

export const filterServiceObject = {
  call_direction: "",
  callfrom_id: "",
  calltype_id: "",
  calltakenby_id: "",
  urgency: "",
  is_closed: "",
  fromDate: null,
  toDate: null,
  timeframe: null,
  currentTotal: 0,
  noOfRecords: 15,
};
export const filterObject = {
  in_out: {
    value: null,
    label: null,
    filterLabel: "In/out",
    name: "call_direction",
  },
  call_type: {
    value: null,
    label: null,
    filterLabel: "Call Type",
    name: "calltype_id",
  },
  call_from: {
    value: null,
    label: null,
    filterLabel: "Call From",
    name: "callfrom_id",
  },
  taken_by: {
    value: null,
    label: null,
    filterLabel: "Call Taken By",
    name: "calltakenby_id",
  },
  urgent: {
    value: null,
    label: null,
    filterLabel: "Urgent",
    name: "urgency",
  },
  closed: {
    value: null,
    label: null,
    filterLabel: "Closed",
    name: "is_closed",
  },
  timeframe: {
    value: null,
    label: null,
    filterLabel: "Timeframe",
    name: "timeframe",
  },
  fromDate: {
    value: null,
    label: null,
    filterLabel: "From",
    name: "fromDate",
  },
  toDate: { value: null, label: null, filterLabel: "To", name: "toDate" },
};

export const call = [
  { text: "In", value: "0" },
  { text: "Out", value: "1" },
];

export const urgency = [
  { text: "Yes", value: "1" },
  { text: "No", value: "0" },
];

export const closed = [
  { text: "Yes", value: "1" },
  { text: "No", value: "0" },
];
export const timeFrame = [
  { label: "Today", value: "1" },
  { label: "This Week", value: "7" },
  { label: "This Month", value: "30" },
  { label: "Last 3 Months", value: "90" },
];

export const defaultContactDetails = {
  user_id: null,
  full_name: null,
  company_name: null,
  department: null,
  title: null,
  email: null,
  phonedetails: [],
  assistants: [],
  address_1: null,
  address_2: null,
  city: null,
  state_id: null,
  zip_code: null,
  country: null,
  notes: null,
  is_active: 1,
  is_favourite: 0,
};

export const newContact = {
  contact_id: 9999,
  full_name: "New Contact",
  is_active: 1,
};

import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import BasicTextField from "../BasicTextField/BasicTextField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BasicLabel from "../BasicLabel/BasicLabel";
import { validateCharctersSpaceOnly } from "../../../../src/Common/Helper";
// import './TextList.scss';

export default class TextList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      placeHolderObj: {
        [this.props.labelProperty]: null,
        [this.props.valueProperty]: null,
      },
    };
  }

  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      placeHolderObj: {
        ...prevState.placeHolderObj,
        [field]: value,
      },
    }));
  };
  addItem = () => {
    if (this.state.placeHolderObj[this.props.labelProperty] === null) {
      return false;
    }
    this.props?.addCallback({ ...this.state.placeHolderObj });
    this.setState((prevState) => ({
      placeHolderObj: {
        ...prevState.placeHolderObj,
        [this.props.labelProperty]: null,
        [this.props.valueProperty]: null,
      },
    }));
  };
  render() {
    return (
      <MDBRow className="g-0">
        {this.props.items?.map((item, index) => {
          return (
            <>
              <MDBCol md={11}>
                <BasicTextField
                  id={this.props?.id}
                  value={item[this.props.labelProperty] || null}
                  onChange={(e) => {
                    const re = /^[a-zA-Z_@./#&+-\s]*$/g;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      validateCharctersSpaceOnly(e.target.value) &&
                        this.props.onChange(e.target.value, index);
                    }
                  }}
                  showMandatory={this.props.showMandatory && index === 0}
                  isMandatory={this.props.isMandatory && index === 0}
                  disabled={this.props?.disabled || false}
                  limitWarning={this.props.limitWarning || ""}
                  limit={this.props.limit}
                  showAsLabel={this.props.disabled || false}
                  placeholder={this.props?.placeholder || ""}
                />
              </MDBCol>

              <MDBCol md={1} className="actions">
                {index !== this.props.items.length - 1 && (
                  <RemoveCircleIcon
                    className="m-1"
                    onClick={() => this.props?.removeCallback(index)}
                  />
                )}{" "}
                {index === this.props.items.length - 1 &&
                  item[this.props.labelProperty] && (
                    <MDBCol md={1} className="actions">
                      <AddCircleIcon
                        className="m-1"
                        onClick={this.props?.addCallback}
                      />
                    </MDBCol>
                  )}
              </MDBCol>
            </>
          );
        })}
      </MDBRow>
    );
  }
}

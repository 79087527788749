import React from "react";
import {
  MDBModal,
  MDBModalBody,
  MDBModalDialog,
  MDBModalContent,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import "./Modal.scss";
//import { withStyles } from '@material-ui/core/styles';
import CancelIcon from "@material-ui/icons/Cancel";
import MessageModal from "../../SharedComponents/MessageModal";
//import { useStyles } from './Config';
import { withUserContext } from "../../../contexts/UserContext";
import ContactList from "../../ContactList/ContactList";
import NewUser from "../../NewUser/NewUser";
import ManageLookup from "../../ManageLookup/ManageLookup";
import MessageHistory from "../../../../src/home/HistoryMessage/HistoryMessage";
import * as appConstants from "../../../constants/appConstants";
import { AppService } from "../../../services/AppService";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMessageModalOpen: false,
      haveUnsavedChanges: false,
      isPostingSuccess: false,
      isPostingFailed: false,
    };
  }

  componentDidMount() {
    this.messageModal$ = AppService.getMessageModalState().subscribe((data) => {
      this.handleMessageModalStatus(data);
    });
  }

  handleMessageModalStatus = (field, value) => {
    if (field === "unsaved") {
      this.setState({ haveUnsavedChanges: value });
    } else if (field === "success") {
      this.setState({
        isPostingSuccess: true,
        isPostingFailed: false,
        haveUnsavedChanges: false,
        isMessageModalOpen: true,
      });
    } else if (field === "fail") {
      this.setState({
        isPostingSuccess: false,
        isPostingFailed: true,
        haveUnsavedChanges: false,
        isMessageModalOpen: true,
      });
    }
  };

  onModalClose = () => {
    if (this.state.haveUnsavedChanges) {
      this.setState({ isMessageModalOpen: true });
    } else {
      this.props.onClose();
    }
  };

  onModalConfirm = () => {
    this.setState({ isMessageModalOpen: false });
    this.props.onClose();
  };

  getModalContent = () => {
    switch (this.props?.modalName) {
      case "ContactList":
        return (
          <ContactList
            handleMessageModalStatus={this.handleMessageModalStatus}
            callback={this.props.callback}
            onModalClose={this.onModalClose}
            onClose={this.props?.onClose}
            comfirmModalTitle={this.props.comfirmModalTitle}
            mode={this.props?.mode}
            contactDetails={this.props?.userData}
            statesList={this.props?.statesList}
            phoneTypeList={this.props?.phoneTypeList}
            onSave={this.props?.onSave}
            {...this.props}
            groupMembers={this.props?.groupMembers}
            updateUserContacts={this.props?.updateUserContacts}
            getUserContacts={this.props?.getUserContacts}
          />
        );
      case "ManageLookup":
        return (
          <ManageLookup
            onClose={this.props?.onClose}
            primaryButtonText={"Save"}
            secondaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
          />
        );
      case "NewUser":
        return (
          <NewUser
            handleMessageModalStatus={this.handleMessageModalStatus}
            callback={this.props.callback}
            onModalClose={this.onModalClose}
            onClose={this.props?.onClose}
            comfirmModalTitle={this.props.comfirmModalTitle}
            mode={this.props?.mode}
            userData={this.props?.userData}
            allData={this.props?.allData}
            {...this.props}
          />
        );
      case "History":
        return (
          <MessageHistory
            callLogId={this.props?.callLogId}
            onClose={this.props?.onClose}
            primaryButtonText={"Cancel"}
            handleMessageModalStatus={this.handleMessageModalStatus}
            onModalClose={this.onModalClose}
          />
        );

      default:
        return null;
    }
  };

  // getClasses = () => {
  //     const { classes } = this.props;
  //     switch (this.props?.modalName) {
  //         case 'NewSet': return classes?.modalName;
  //         case 'RevampSet': return classes?.revampSet;
  //         default: return '';
  //     }
  // }

  handlePermissions = () => {
    let userPermissions = this.props?.userContext?.active_tenant?.permissions;
    let showSave = false;
    switch (this.props?.modalName) {
      case "ManageLookup":
        showSave = userPermissions?.isManageLookups
          ? userPermissions?.isManageLookups
          : false;
        break;
      case "NewSet":
        showSave = userPermissions?.isModifySet
          ? userPermissions?.isModifySet
          : false;
        break;
      case "RevampSet":
        showSave = userPermissions?.isModifySet
          ? userPermissions?.isModifySet
          : false;
        break;
      case "AddCatalogFile":
        showSave = userPermissions?.isModifySet
          ? userPermissions?.isModifySet
          : false;
        break;
      case "AddCadFiles":
        showSave = userPermissions?.isModifySet
          ? userPermissions?.isModifySet
          : false;
        break;
      case "EmailCatalog":
        showSave = userPermissions?.isViewSet
          ? userPermissions?.isViewSet
          : false;
        break;
      case "ExportCatalog":
        showSave = userPermissions?.isViewSet
          ? userPermissions?.isViewSet
          : false;
        break;
      case "PrintCatalog":
        showSave = userPermissions?.isViewSet
          ? userPermissions?.isViewSet
          : false;
        break;
      case "NewUser":
        showSave = userPermissions?.isManageUser
          ? userPermissions?.isManageUser
          : false;
        break;
    }
    return showSave;
  };

  getClassesForModal = () => {
    switch (this.props?.modalName) {
      case "NewSet":
        return "md";
      case "RevampSet":
        return "sm";
      case "AddCadFiles":
        return "md";
      case "AddCatalogFile":
        return "md";
      case "EmailCatalog":
        return "lg";
      case "PrintCatalog":
        return "lg";
      case "ExportCatalog":
        return "lg";
      case "NewUser":
        return "sm";
      case "ContactList":
        return "sm";
      case "ManageLookup":
        return "lg";
      case "History":
        return "lg";
      default:
        return "md";
    }
  };

  getCustomClasses() {
    switch (this.props?.modalName) {
      case "ManageLookup":
        return "ModalContentContainer lookupModalContainer";
      case "History":
        return "ModalContentContainer historyModalContainer";

      default:
        return "ModalContentContainer";
    }
  }

  render() {
    let headerName = this.props?.currentContactListHeader?.length > 0 ? this.props?.currentContactListHeader : this.props?.currentGroupMember?.full_name;
    return (
      <div className={this.getCustomClasses()}>
        <MDBModal
          staticBackdrop
          closeOnEsc={false}
          tabIndex="-1"
          show={this.props?.open ? this.props?.open : false}
        >
          <MDBModalDialog size={this.getClassesForModal()}>
            <MDBModalBody>
              <MDBModalContent className={this.props?.modalName === "ContactList" ? "ContactDetailsWidth" : ""}>
                <div>
                  <MDBContainer fluid className="padding-hor-12">
                    <MDBRow className="ModalContent">
                      <MDBCol>
                        <MDBRow className="ModalHeader" id={"modal_header"}>
                          <MDBCol md={11} className="HeaderText">
                            {this.props.comfirmModalTitle}
                            {this.props?.mode === appConstants.LABEL_FIND
                              ? " - " + headerName
                              : ""}
                          </MDBCol>
                          {/* <MDBCol md={4} className="HeaderText"></MDBCol> */}
                          <MDBCol
                            md={1}
                            className="closeIcon"
                            onClick={this.onModalClose}
                          >
                            <CancelIcon style={{ fill: "white" }} />
                          </MDBCol>
                        </MDBRow>
                        {/* <MDBRow className="ModalBody" disabled={!this.handlePermissions()}> */}
                        <MDBRow className="ModalBody">
                          <MDBCol md={12} className="Content">
                            {this.getModalContent()}
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="footer"></MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                </div>
              </MDBModalContent>
            </MDBModalBody>
          </MDBModalDialog>
          <MessageModal
            open={this.state.isMessageModalOpen}
            showError={this.state.haveUnsavedChanges || false}
            showFail={this.state.isPostingFailed || false}
            showSuccess={this.state.isPostingSuccess || false}
            primaryButtonText={"Ok"}
            secondaryButtonText={"Cancel"}
            hideCancel={
              this.state.isPostingFailed || this.state.isPostingSuccess || false
            }
            onConfirm={() => this.onModalConfirm()}
            handleClose={(e) => this.setState({ isMessageModalOpen: false })}
          />
        </MDBModal>
      </div>
    );
  }
}

// export default withUserContext(withStyles(useStyles)(Modal));
export default withUserContext(Modal);

export const historyMessageConfig = {
  headings: [
    {
      headingLabel: "In/Out",
      icon: "",
      width: "8%",
    },
    {
      headingLabel: "Call Date",
      icon: "",
      width: "15%",
    },
    {
      headingLabel: "Call From",
      icon: "",
      width: "20%",
    },
    {
      headingLabel: "Call Type",
      icon: "",
      width: "15%",
    },
    {
      headingLabel: "Message",
      icon: "",
      width: "20%",
    },
    {
      headingLabel: "Call Back No",
      icon: "",
      width: "15%",
    },
  ],
  dataNodes: [
    "call_direction_value",
    "created_at",
    "callfrom",
    "calltype_value",
    "message",
    "callback_value",
  ],
  primaryKey: "calllog_id",
  rowColorFlag: "is_urgent",
};

import React from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// import ArrowCircleLeft from "@material-ui/icons/ArrowCircleLeft";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./ContactList.scss";

import BasicTabs from "../SharedComponents/BasicTabs/BasicTabs";
import ContactDetails from "../ContactDetails/ContactDetails";
import FindContact from "../FindContact/FindContact";
import PhoneLogService from "../../services/service";
import * as Constants from "../../constants/constants";
import * as appConstants from "../../constants/appConstants";
import SelectField from "../SharedComponents/SelectField/SelectField";
import { withUserContext } from "../../contexts/UserContext";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";

class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactDetails: null,
      currentContact: null,
      isLoading: false,
      phoneList: [{ number: null, type: null }],
      tabValue: 1,
      contactIndex: 1,
      totalContacts: 0,
      groupMembers: [],
      selectedUserId: this.props?.currentGroupMember?.user_id,
      tempContactIndex: 1
    };
    this.tabList = null;
  }

  componentDidMount = (prevProps, prevState) => {
    this.getGroupMembers();
    this.setState({
      contactDetails: this.props?.contactDetails,
      currentContact: this.props?.contactDetails[0] || [],
      totalContacts: this.props?.contactDetails.length,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.contactDetails != this.props.contactDetails) {
      this.setState({
        contactIndex: 1,
        tempContactIndex: 1,
        contactDetails: this.props?.contactDetails,
        currentContact: this.props?.contactDetails[0] || [],
        totalContacts: this.props?.contactDetails.length,
      });
    }
  }

  getGroupMembers = () => {
    PhoneLogService.getDataWithPath(
      Constants.phoneLogServiceBaseUrl +
      "/getgroupmembers?userId=" +
      this.props?.userContext?.user_profile?.user_id,
      this.props?.userContext?.user_profile?.tenant_id
    ).then(
      (response) => {
        let formattedList = response.data.map(item => ({ value: item.user_id, label: item.display_name }))
        this.setState(
          {
            groupMembers: formattedList
          });
      },
      (err) => {
        console.log("Error in fetching getGroupMembers", err);
      }
    );
  };

  handleFilterChange = (field, value) => {
    // this.setState(prevState => ({
    //     filterCriteria: {
    //         ...prevState.filterCriteria,
    //         [field]: value
    //     }
    // }))
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  findUserContacts = (searchObj) => {
    this.setState({ isLoading: true, tabValue: 1, noResultsFound: false });
    PhoneLogService.getUserContactsWithSearchObject(
      Constants.phoneLogServiceBaseUrl + "/getusercontacts",
      this.props?.userContext?.user_profile?.tenant_id,
      this.state.selectedUserId,
      searchObj
    ).then(
      (response) => {
        if (response.data.length === 0) {
          this.setState({ noResultsFound: true });
        }
        this.setState({
          contactDetails: response.data,
          isLoading: false,
          contactIndex: 1,
          tempContactIndex: 1,
          totalContacts: response.data.length,
          currentContact: response.data[0] || [],
        });
      },
      (err) => {
        this.setState({ isLoading: false, noResultsFound: false });
        console.log("Error in fetching user contacts", err);
      }
    );
  };

  forwardClick = () => {
    if (this.state.tempContactIndex + 1 <= this.state.totalContacts) {
      var contact = this.state?.contactDetails[this.state.tempContactIndex];
      var index = this.state.tempContactIndex + 1;
      this.setState({
        contactIndex: index,
        currentContact: contact,
        tempContactIndex: index,
      });
    }
  };

  backClick = () => {
    var index = this.state.tempContactIndex - 1
    if (index > 0) {
      this.setState({
        contactIndex: index,
        currentContact: this.state?.contactDetails[index - 1],
        tempContactIndex: index
      });
    }
  };

  navigateToIndex = (e) => {
    if (e.keyCode === 13) {
      let tempIndex = this.state.tempContactIndex > this.state.totalContacts ? this.state.totalContacts : this.state.tempContactIndex < 0 ? 1 : this.state.tempContactIndex
      this.setState({ currentIndex: tempIndex, currentContact: this.state?.contactDetails[tempIndex - 1], contactIndex: tempIndex, tempContactIndex: tempIndex });
    }
  }

  rendertab = () => {
    switch (this.state.tabValue) {
      case 1:
        return (
          <ContactDetails
            onSave={this.props.onSave}
            onClose={this.props.onClose}
            contactDetails={this.state?.currentContact}
            statesList={this.props?.statesList}
            phoneTypeList={this.props?.phoneTypeList}
            mode={this.props?.mode}
            isLoading={this.state.isLoading}
            contactIndex={this.state.contactIndex}
            noResultsFound={this.state.noResultsFound}
          />
        );
      case 2:
        return (
          <FindContact
            onClose={this.props.onClose}
            onGo={this.findUserContacts}
          />
        );
      default:
        return (
          <FindContact
            onClose={this.props.onClose}
            onGo={this.findUserContacts}
          />
        );
    }
  };

  onSave = () => {};

  handleUserNameChange = (value) => {
    this.setState({ selectedUserId: value })
    let selectedName = this.state.groupMembers?.filter(item => item.value === value)
    this.props?.getUserContacts(value, selectedName[0].label)
  }

  render() {
    return (
      <div className="contact-list-container">
        {this.state.isLoading ? (
          <div className="New-Set-Loader">
            {" "}
            <CircularProgress color="inherit" size={24} />{" "}
          </div>
        ) : (
            <>
            {this.props?.comfirmModalTitle !== 'New Contact' ? 
              <div>
                <MDBRow>
                  <MDBCol md={3}>
                    <BasicLabel
                      text="Contact Name"
                    />
                  </MDBCol>
                  <MDBCol md={4}>
                    <SelectField
                      placeHolderText={"Select"}
                      options={this.state.groupMembers}
                      value={this.state.selectedUserId}
                      onChange={(e) => this.handleUserNameChange(e.target.value)}
                      
                    />
                  </MDBCol>
                </MDBRow>
              </div>
            : null }
              <div>
                {this.props.mode === appConstants.LABEL_FIND && (
                  <MDBRow className={"align-items-center"}>
                    <MDBCol md={8}>
                      <BasicTabs
                        value={this.state.tabValue}
                        tabList={[
                          { label: "Details", value: 1 },
                          { label: "Find", value: 2 },
                        ]}
                        onChange={(e, newValue) => {
                          this.handleTabChange(e, newValue);
                        }}
                      />
                    </MDBCol>
                    {this.state.tabValue === 1 && (
                      <>
                        <MDBCol md={1} className={"align-middle"}>
                          <ArrowBackIosIcon
                            className="m-1"
                            onClick={this.backClick}
                          />
                        </MDBCol>
                        {this.state?.totalContacts === 0 ? 0 :
                          <MDBCol md={1} className="contactIndexWidth">
                            <BasicTextField
                              value = {this.state.tempContactIndex}
                              onChange={(e) => this.setState({ tempContactIndex: parseInt(e.target.value)})}
                              onKeyUp={(e) => this.navigateToIndex(e)}
                            />
                          </MDBCol>
                        }
                          {" of " +
                          this.state?.totalContacts}
                        <MDBCol md={1} className={"align-middle"}>
                          <ArrowForwardIos
                            className="m-1"
                            onClick={this.forwardClick}
                          />
                        </MDBCol>
                      </>
                    )}
                  </MDBRow>
                )}
                {this.state?.currentContact && this.rendertab()}
              </div>
            </>
          )}
      </div>
    );
  }
}
export default withUserContext(ContactList);

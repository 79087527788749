import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import SelectField from "../SelectField/SelectField";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BasicTextField from "../BasicTextField/BasicTextField";
import "./PhoneList.scss";
import {
  validateCustomPhoneNumber,
  validatePlusCharacter,
} from "../../../Common/Helper";

export default class PhoneList extends React.Component {
  constructor(props) {
    super(props);
    this.placeHolderObj = {
      [this.props.numberValueProperty]: null,
      [this.props.typeValueProperty]: null,
    };
  }

  handleOnChange = (field, value) => {
    this.setState((prevState) => ({
      placeHolderObj: {
        ...prevState.placeHolderObj,
        [field]: value,
      },
    }));
  };

  render() {
    let phonelist=this.props.items.filter(a=>a.is_deleted!=1);
    let count=-1;
    return (
      <MDBRow className="g-0">
        {this.props.items.map((item, index) => {
          if(item.is_deleted !== 1){
            count=count+1;
          return (
            <>
              <MDBCol md={6} className={"mt-1"}>
                <BasicTextField
                  placeholder={
                    this.props?.phoneNumberPlaceholder || ""
                  }
                  onChange={(e) => {
                    this.props.handlePhoneListOnChange(
                      e.target.value,
                      index,
                      this.props.numberValueProperty
                    );
                  }}
                  showMandatory={this.props.showMandatory && index === 0}
                  isMandatory={this.props.isMandatory && index === 0}
                  value={item[this.props.numberValueProperty] || null}
                  limit={this.props?.limit}
                  limitWarning={this.props?.limitWarning}
                  fieldValid={
                    item[this.props.numberValueProperty]
                      ? !validateCustomPhoneNumber(
                          item[this.props.numberValueProperty]
                        )
                      : false
                  }
                  inValidInput={"Please enter valid phone"}
                />
              </MDBCol>
              <MDBCol md={3}>
                <SelectField
                  size="small"
                  options={
                    this.props?.callTypeOptions?.map((item) => ({
                      value: item.id,
                      label: item.value,
                      is_active: item.is_active,
                    })) || []
                  }
                  showMandatory={this.props.showMandatory && index === 0}
                  isMandatory={this.props.isMandatory && index === 0}
                  value={item[this.props.typeValueProperty] || null}
                  onChange={(e) => {
                    this.props.handlePhoneListOnChange(
                      e.target.value,
                      index,
                      this.props.typeValueProperty
                    );
                  }}
                  placeholder={this.props?.phoneTypePlaceholder || "-Select-"}
                />
              </MDBCol>
              <MDBCol md={0} className="actions">
                {phonelist.length > 1 ? (
                  <RemoveCircleIcon
                    className="m-1"
                    onClick={() => this.props?.removeCallback(index)}
                  />
                ):null}
                {(count === phonelist.length - 1 ) &&
                  item[this.props.typeValueProperty] &&
                  item[this.props.numberValueProperty] && (
                    // <MDBCol md={3} className="actions">
                      <AddCircleIcon
                        className="m-1"
                        onClick={this.props?.addCallback}
                      />
                    // </MDBCol>
                  )}
              </MDBCol>
            </>
          );}
          else{
            return <></>;
          }
        })}
      </MDBRow>
    );
  }
}

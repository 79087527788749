import React, { useState, useEffect } from "react";
import { MDBIcon, MDBRow, MDBCol } from "mdbreact";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "./shared.scss";
import Typography from "@material-ui/core/Typography";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 1000,
});

const SearchSelectField = (props) => {
  const [contacts, setContacts] = useState(props.options);
  function pinIconClick(e, value) {
    e.stopPropagation();
    props.pinIconClick(value);
    useEffect(() => {
      setContacts(props.options);
    });
  }

  return props.multiple ? (
    <div className="SearchSelectFieldContainer">
      <Autocomplete
        id={props?.id}
        multiple
        limitTags={1}
        disabled={props?.disabled ? props?.disabled : false}
        options={contacts ? contacts : []}
        value={props?.value ? props?.value : null}
        getOptionLabel={(option) => (option.text ? option.text : "")}
        getOptionSelected={(option, value) => option.value === value.value}
        renderOption={(props, option) => (
          <>
            <MenuItem
              key={props?.value}
              disabled={props?.is_active === 0 ? true : false}
              value={props?.value}
            >
              {props?.text}
            </MenuItem>
          </>
        )}
        //getOptionDisabled={(option => option?.is_active == 0)}
        placeholder={props?.placeholder || ""}
        filterOptions={filterOptions}
        style={{ width: props.width ? props.width : "200px" }}
        popupIcon={
          props.icon === "hide" ? (
            ""
          ) : (
            <MDBIcon icon="chevron-right" size="sm" />
          )
        }
        onChange={props?.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={props?.placeholder || ""}
          />
        )}
      />
    </div>
  ) : (
    <div className="SearchSelectFieldContainer">
      <Autocomplete
        id={props?.id}
        disabled={props?.disabled ? props?.disabled : false}
        options={props?.options ? props?.options : []}
        value={props?.value ? props?.value : null}
        getOptionLabel={(option) => (option.text ? option.text : "")}
        getOptionSelected={(option, value) => option.value === value.value}
        renderOption={(props, option) => (
          <>
            <MenuItem
              key={props?.value}
              className={`${
                props?.value?.contact_id === 9999 ? "bg-blue-custom" : ""
              }`}
              disabled={props?.is_active === 0 ? true : false}
              value={props?.value}
            >
              <div class="flex-container">
                <div class="option-value">
                  <span>{props?.text}</span>
                </div>
                <div class="pinIcon">
                  <Typography variant="body2">
                    <MDBIcon
                      fas
                      size="21x"
                      icon={"thumbtack"}
                      className={
                        props?.value?.is_favourite
                          ? "m-2 ml-3 float-end blueIcon"
                          : "m-2 ml-3 float-end blackIcon"
                      }
                      onClick={(e) => {
                        pinIconClick(e, props);
                      }}
                    />
                  </Typography>
                </div>
              </div>
            </MenuItem>
          </>
        )}
        //getOptionDisabled={(option => option?.is_active == 0)}
        placeholder={props?.placeholder || ""}
        filterOptions={filterOptions}
        style={{
          width: props.width ? props.width : "200px",
          display: props.editIcon ? "inline-block" : "block",
        }}
        popupIcon={
          props.icon === "hide" ? (
            ""
          ) : (
            <MDBIcon icon="chevron-right" size="sm" />
          )
        }
        onChange={props?.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={props?.placeholder || ""}
          />
        )}
      />
      {props.editIcon && props?.value && (
        <MDBIcon
          fas
          size="1x"
          icon="pen"
          className="ml-2 p-1 bg-primary shadow-none text-white"
          onClick={props?.onEditClicked}
        />
      )}
    </div>
  );
};

export default SearchSelectField;

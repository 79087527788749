import fetch from "cross-fetch";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import "./AsynchronousSearch.scss";
import SearchIcon from "@material-ui/icons/Search";
import { selectOptionsFeature, selectOptionsTV } from "./Asynchronous";
import SearchResultsLayout from "./SearchResultsLayout";
import { withUserContext } from "../../../contexts/UserContext";
import * as Constants from "../../../constants/constants";
import PhoneLogService from "../../../services/service";
//import ModalComponent from '../../../Common/DefaultModalLayout/Modal/Modal';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import {
  talentNavList,
  agentNavList,
  productionCompanyList,
  crewList,
  representativeNavList,
} from "../../../constants/NavList";
import { useHistory } from "react-router-dom";

class AsynchronousSearch extends React.Component {
  // history = useHistory();
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      options: [],
      loading: false,
      isOpen: false,
      parentDropDownSelection: 1,
      childDropDownSelection: [],
      defautSelection: [], // searchData[0],
      searchKeyWord: "",
      totalCount: null,
      talentId: null,
      agencyId: null,
      repId: null,
      crewId: null,
      roleId: null,
      prodCompId: null,
      performerId: null,
      showTalentPopup: false,
      showAgencyPopup: false,
      showProductionPopup: false,
      showCrewPopup: false,
      showRepresentativePopup: false,
      showRolesPopup: false,
      showPerformer: false,
    };
  }
  componentDidMount() {}

  functions = {
    handleShowTalentPopup: (id = null) => {
      this.setState({ showTalentPopup: true, talentId: id });
    },
    handleHideTalentPopup: () => {
      this.setState({ showTalentPopup: false });
    },
    handleShowAgencyPopup: (agencyId = null) => {
      this.setState({ showAgencyPopup: true, agencyId: agencyId });
    },
    handleHideAgencyPopup: () => {
      this.setState({ showAgencyPopup: false });
    },
    handleShowProductionPopup: (prodCompId = null) => {
      this.setState({ showProductionPopup: true, prodCompId: prodCompId });
    },
    handleShowCrewPopup: (crewId = null) => {
      this.setState({ showCrewPopup: true, crewId: crewId });
    },
    handleHideCrewPopup: () => {
      this.setState({ showCrewPopup: false });
    },
    handleHideProductionPopup: () => {
      this.setState({ showProductionPopup: false });
    },
    handleShowRepresentativePopup: (repId = null) => {
      this.setState({ showRepresentativePopup: true, repId: repId });
    },
    handleHideRepresentativePopup: () => {
      this.setState({ showRepresentativePopup: false });
    },
    handleShowRolePopup: (roleId) => {
      this.setState({ showRolesPopup: true, roleId: roleId });
    },
    handleHideRolePopup: () => {
      this.setState({ showRolesPopup: false });
    },
    handlePerformer: (performerId) => {
      this.setState({ showPerformer: true, performerId: performerId });
    },
    hidePerformer: () => {
      this.setState({ showPerformer: false });
    },
  };

  onChangeHandle = (searchString) => {
    let selectOptions =
      this.props.userContext.active_tenant.tenant_name !== "Feature Casting"
        ? selectOptionsTV
        : selectOptionsFeature;
    if (searchString.length > 0) {
      let searchObj = {
        data_type: selectOptions.find(
          (item) => item.value === this.state.parentDropDownSelection
        ).searchType,
        search_string: searchString,
        page_count: null,
        page_no: null,
      };
      PhoneLogService.getDataParams(
        Constants.phoneLogServiceBaseUrl + "/globalSearch",
        searchObj,
        this.props.userContext?.active_tenant?.tenant_id
      ).then(
        (response) => {
          this.setState({
            options: response.data.data,
            loading: false,
            searchKeyWord: searchString,
            totalCount: response.data.total_count,
          });
        },
        (err) => {
          this.setState({ options: [], loading: false });
        }
      );
    } else {
      this.setState({
        options: [],
        loading: false,
        searchKeyWord: "",
        totalCount: 0,
      });
    }
  };

  filterOptions = createFilterOptions({
    stringify: (option) => option.name,
  });

  handleParentSearchChange = (event) => {
    this.setState({ parentDropDownSelection: event.target.value, options: [] });
  };

  handleSearchOption = (newValue) => {
    let prodOrSeason =
      this.props.userContext.active_tenant.tenant_name == "TV Casting"
        ? "ps"
        : "p";
    if (newValue.data_type == "SHOWS") {
      window.open(
        `/prod-deal-contracts/${prodOrSeason}/${newValue.id.slice(6)}`
      );
    } else if (newValue.data_type == "EPISODES") {
      //window.open(`/prod-deal-contracts/ps/${newValue.id}`) prodId
    } else if (newValue.data_type == "TALENT") {
      this.functions.handleShowTalentPopup(parseInt(newValue.id.slice(7)));
    } else if (newValue.data_type == "AGENCY") {
      this.functions.handleShowAgencyPopup(parseInt(newValue.id.slice(7)));
    } else if (newValue.data_type == "REPRESENTATIVES") {
      this.functions.handleShowRepresentativePopup(
        parseInt(newValue.id.slice(5))
      );
    } else if (newValue.data_type == "PRODUCTION_COMPANIES") {
      this.functions.handleShowProductionPopup(parseInt(newValue.id.slice(3)));
    } else if (newValue.data_type == "CREW") {
      this.functions.handleShowCrewPopup(parseInt(newValue.id.slice(5)));
    } else if (newValue.data_type == "ROLES") {
      //this.functions.handleShowRolePopup(parseInt(newValue.id.slice(6)))
      //prod-deal-contracts/ps/1/ep/2/perf/6541
      window.open(`/prod-deal-contracts/ps/1/ep/2/perf/6541`);
    } else if (newValue.data_type == "PERFORMER") {
      //this.functions.handlePerformer(parseInt(newValue.id.slice(5)))
      window.open(`/prod-deal-contracts/ps/1`);
    }
  };

  render() {
    let selectOptions =
      this.props.userContext.active_tenant.tenant_name !== "Feature Casting"
        ? selectOptionsTV
        : selectOptionsFeature;
    return (
      <>
        <div class="row PLSearch">
          <div class="col-3" id="txtAllSelect">
            <Select
              variant="outlined"
              displayEmpty
              className="selectStyle"
              defaultValue={this.state.parentDropDownSelection}
              onChange={this.handleParentSearchChange}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }}
            >
              {selectOptions?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div class="col-9 ptPt4" id="txtSearch">
            <Autocomplete
              value={this.state.defautSelection}
              id="asynchronous-demo"
              className="autoCompleteStyle"
              open={this.state.open}
              onOpen={() => {
                this.setState({ open: true });
              }}
              onClose={() => {
                this.setState({ open: false });
              }}
              renderOption={(props, option) => (
                <>
                  <li {...props}>
                    <SearchResultsLayout
                      searchResult={props}
                    ></SearchResultsLayout>
                  </li>
                </>
              )}
              onChange={(event, newValue) => {
                if (newValue && newValue.result_type != "recordCount") {
                  this.handleSearchOption(newValue);
                } else if (newValue && newValue.result_type == "recordCount") {
                  this.setState({ defautSelection: [] });
                  //Will be replacing with History
                  window.location.href =
                    "/userSearch/" +
                    this.state.parentDropDownSelection +
                    "/" +
                    this.state.searchKeyWord;
                  //  this.history.push('/userSearch/' + this.state.parentDropDownSelection + this.state.defautSelection)
                } else {
                  this.setState({ defautSelection: [] });
                }
                this.setState({ options: [], totalCount: 0 });
              }}
              filterOptions={(options, params) => {
                options = this.filterOptions(options, params);
                if (this.state.totalCount > 0) {
                  let type = this.state.totalCount === 1 ? "result" : "results";
                  options.unshift({
                    sub_group: `${this.state.totalCount} search ${type}`,
                  });
                  if (this.state.totalCount > 1) {
                    options.push({
                      name: `See all ${this.state.totalCount} results`,
                      result_type: "recordCount",
                    });
                  }
                } else {
                  options = [];
                }
                return options;
              }}
              getOptionLabel={(option) => option.name}
              options={this.state.options}
              loading={this.state.loading}
              renderTags={() => null}
              noOptionsText="No labels"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search..."
                  variant="outlined"
                  onChange={(ev) => {
                    if (ev.target.value !== "" || ev.target.value !== null) {
                      this.setState({ loading: true }, () => {
                        this.onChangeHandle(ev.target.value);
                      });
                    } else {
                      this.setState({ loading: true, options: [] });
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {this.state.loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        <SearchIcon className="MRN3" />
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
        {/*    this.state.showTalentPopup ?
                        <ModalComponent
                            navFuns={this.functions}
                            talentId={this.state.talentId}
                            open={this.state.showTalentPopup}
                            handleClose={this.functions.handleHideTalentPopup}
                            tabList={talentNavList}
                            bodyText={"Search for Talent to view their Data, or create a new Talent"}
                            searchPlaceholder={"Search Talent by name or SSN"}
                            headerText={"Talent"} 
                            addButtonText={"New Talent"}
                            dataUrl='talentDetails' /> : null
                }
                {
                    this.state.showAgencyPopup ?
                        <ModalComponent
                            navFuns={this.functions}
                            open={this.state.showAgencyPopup}
                            handleClose={this.functions.handleHideAgencyPopup}
                            tabList={agentNavList}
                            bodyText={"Search for an Agency to view its Data"}
                            searchPlaceholder={"Search Agencies by name, type or ID"}
                            addButtonText={"New Agency"}
                            agencyId={this.state.agencyId}
                            headerText={"Agency"} dataUrl='agencyDetails' /> : null
                }
                {
                    this.state.showProductionPopup ?
                        <ModalComponent
                            navFuns={this.functions}
                            open={this.state.showProductionPopup}
                            handleClose={this.functions.handleHideProductionPopup}
                            tabList={productionCompanyList}
                            prodCompId={this.state.prodCompId}
                            bodyText={"Search for Pr.oduction Companies to view its Data"}
                            searchPlaceholder={"Search production companies by name, type or ID"}
                            addButtonText={"Production Co."}
                            headerText={"Production Companies"} dataUrl='productionCompany' /> : null
                }
                {
                    this.state.showCrewPopup ?
                        <ModalComponent
                            navFuns={this.functions}
                            open={this.state.showCrewPopup}
                            crewId={this.state.crewId}
                            handleClose={this.functions.handleHideCrewPopup}
                            tabList={crewList}
                            bodyText={"Search for a Crew Member to view their Data"}
                            searchPlaceholder={"Search by Crew Member name, type or occupation"}
                            addButtonText={"New Crew Member"}
                            headerText={"Crew"} dataUrl='crewDetails' /> : null
                }
                {
                    this.state.showRepresentativePopup ?
                        <ModalComponent
                            navFuns={this.functions}
                            open={this.state.showRepresentativePopup}
                            handleClose={this.functions.handleHideRepresentativePopup}
                            tabList={representativeNavList}
                            bodyText={"Search for a Representative to view its Data"}
                            searchPlaceholder={"Search by Representative name or Agency name"}
                            addButtonText={"New Representative"}
                            repId={this.state.repId}
                            headerText={"Representatives"} dataUrl='representativeDetails' /> : null
                }
                { /*{
                    this.state.showRolesPopup &&
                        <MainViewModalComponent
                            open={this.state.showRolesPopup}
                            handleClose={this.functions.handleHideRolePopup}
                            headerText={"Add Performers by Roles"}
                            modalName={"Roles"}
                            navFuns={this.functions}
                            mode={'Both'}
                        />

                } */}
      </>
    );
  }
}
export default withUserContext(AsynchronousSearch);

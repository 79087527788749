export const getFormattedDate = (date, dateField = false) => {
  if (date) {
    let formattedDate = new Date(date);
    let year = formattedDate.getFullYear();
    let month = (1 + formattedDate.getMonth()).toString().padStart(2, "0");
    let day = formattedDate.getDate().toString().padStart(2, "0");
    if (dateField) return year + "-" + month + "-" + day;
    else return month + "/" + day + "/" + year;
  }
};
export const getAge = (currentDate) => {
  if (currentDate) {
    var today = new Date();
    var birthDate = new Date(currentDate);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
};

export const getAddress = (field, item, value, addressConfig) => {
  item[field] = value;
  let a = value.split("\n");
  item[addressConfig.address1] = !a[0] ? null : a[0];
  item[addressConfig.address2] = !a[1] ? null : a[1];
  let temp = "";
  for (var i = 2; i < a.length; i++) {
    temp += a[i];
  }
  item[addressConfig.address3] = !temp ? null : temp;
  return item;
};

export const getFormattedTime = (date) => {
  let formattedDate = new Date(date);
  let hours = formattedDate.getHours();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12;
  hours = hours < 10 ? "0" + hours : hours;
  let mins = formattedDate.getMinutes();
  mins = mins < 10 ? "0" + mins : mins;
  return hours + ":" + mins + " " + ampm;
};

export const getDateAndTime = (dateEntry) => {
  let date = getFormattedDate(dateEntry);
  let time = getFormattedTime(dateEntry);
  return date + ", " + time;
};

export const validateEmail = (email) => {
  var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //var emailRegex = /^[A-Za-z0-9]+([\.-_]?[A-Za-z0-9]+)*@[A-Za-z0-9]+([\.-]?[A-Za-z0-9]+)*(\.[A-Za-z]{2,3})+$/;
  return emailRegex.test(email);
};

export const maskSsn = (ssn) => {
  return ssn ? `xxx-xxx-${ssn?.substr(ssn?.length - 4)}` : ssn;
};

// export const validatePhone = (phone) => {
//     let phoneRegex = /^[0-9\-\(\)+]+$/;
//     return phoneRegex.test(phone);
// }

export const validateCharctersOnly = (text) => {
  let regex = /^[a-zA-Z!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
  return regex.test(text);
};

export const validateCharctersSpaceOnly = (text) => {
  let regex = /^[a-zA-Z!@#$&() \n"'-~%^_{}\]\\`.+,/\ ]*$/;
  return regex.test(text);
};
export const validateCharctersApostrophe = (text) => {
  let regex = /^[a-zA-Z \n'-]+$/;
  return regex.test(text);
};
export const validateNumbersOnly = (text) => {
  let regex = /^[0-9]*$/;
  return regex.test(text);
};

export const validateCharNumOnly = (text) => {
  let regex = /^[a-zA-Z0-9]*$/i;
  return regex.test(text);
};

export const validateCharNumHyphenSlashOnly = (text) => {
  let regex = /^[a-zA-Z0-9-/!@#$&()\\-`.+,/\ ]*$/i;
  return regex.test(text);
};

export const validateCustomPhoneNumber = (text) => {
  let regex = /^[a-zA-Z0-9-/()\\-`.+,/\ ]*$/i;
  return regex.test(text);
};
export const validateCharNumWithHyphenSlash = (text) => {
  let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
  return regex.test(text);
};

export const validateCharNumWithHyphen = (text) => {
  let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
  return regex.test(text);
};

export const validateCharNumWithHyphenSpace = (text) => {
  let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
  return text ? regex.test(text) : true;
};

export const validateCharNumCommaDotWithSpace = (text) => {
  let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
  return regex.test(text);
};

export const validateCharNumOnlyWithSpace = (text) => {
  let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
  return text ? regex.test(text) : true;
};

export const validateAlpVarCharNumOnlyWithSpace = (text) => {
  let regex = /^[a-zA-Z0-9ÀÁĄ́ÂÃÄB́B̀ĆÉÈÍÌǴḰḾŃǸÓÒṔŔŚS̀ÚẂÝŹ!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
  return text ? regex.test(text) : true;
};

export const validateAlpVarCharOnlyWithSpace = (text) => {
  let regex = /^[a-zA-ZÀÁĄ́ÂÃÄB́B̀ĆÉÈÍÌǴḰḾŃǸÓÒṔŔŚS̀ÚẂÝŹ!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/;
  return text ? regex.test(text) : true;
};

export const validateNumPlusOnly = (text) => {
  let regex = /^[0-9+]*$/;
  return regex.test(text);
};

export const validateNumDotOnly = (text) => {
  let regex = /^[0-9]*\.?[0-9]*$/;
  return regex.test(text);
};

export const validateCharNumHashOnly = (text) => {
  let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
  return regex.test(text);
};

export const validateCharNumRateOnly = (text) => {
  let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]*$/i;
  return regex.test(text);
};

export const validateCharNumHashSpaceOnly = (text) => {
  // let regex = /^[a-zA-Z0-9#]+\s?[a-zA-Z0-9#]*$/i;
  let regex = /^[a-zA-Z0-9!@#$&()"'-~%^_{}\]\\`.+,/\ ]/i;
  return regex.test(text);
};

export const validateNumHyphenOnly = (text) => {
  let regex = /^[0-9-]*$/;
  return regex.test(text);
};

export const validateUSNumber = (text) => {
  let charRegex = /\(\d{3}\)-\d{3}-\d{4}$/;
  return charRegex.test(text);
};

export const validatePhoneNumber = (text) => {
  let charRegex = /^\d{10}$/;
  return charRegex.test(text);
};

export const validateIntlNumber = (text) => {
  let charRegex = /\+\d{1,20}$/;
  return charRegex.test(text);
};
export const validatePlusCharacter = (text) => {
  let charRegex = /^[+]/;
  return charRegex.test(text);
};

export const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export const genReportName = (obj) => {
  let { reportName, production, season, episode, performer } = obj;
  let name = `${reportName ? reportName + "_" : ""}${
    production ? production + "_" : ""
  }${season ? season + "_" : ""}${episode ? episode + "_" : ""}${
    performer ? performer : ""
  }`;
  name = name.trim().replaceAll(" ", "_");
  return name.charAt(name.length - 1) === "_" ? name.slice(0, -1) : name;
};

export const userListConfig = {
  headings: [
    {
      headingLabel: "Full Name",
      icon: "",
      width: "15%",
      inputType: "text",
    },
    {
      headingLabel: "Display name",
      icon: "",
      width: "15%",
      inputType: "text",
    },
    {
      headingLabel: "Tenant",
      icon: "",
      width: "8%",
      inputType: "text",
    },
    {
      headingLabel: "User Type",
      icon: "",
      width: "8%",
      inputType: "text",
    },
    {
      headingLabel: "Email",
      icon: "",
      width: "15%",
      inputType: "text",
    },
    {
      headingLabel: "Notification Email",
      icon: "",
      width: "15%",
      inputType: "text",
    },
    {
      headingLabel: "Department",
      icon: "",
      width: "10%",
      inputType: "text",
    },
    {
      headingLabel: "Status",
      icon: "",
      width: "10%",
      inputType: "text",
    },
  ],
  dataNodes: [
    "full_name",
    "display_name",
    "tenant_name",
    "user_type",
    "login_email",
    "notification_email",
    "department_name",
    "is_active_text",
  ],
  primaryKey: "user_id",
  actions: ["pen"],
  changeColourToRed: ["In-Active"],
  changeColourToGreen: ["Active"],
};

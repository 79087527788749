export const initialUserDetails = {
  department_id: null,
  department_name: null,
  display_name: null,
  full_name: null,
  is_active: 1,
  is_user_provisioner: 0,
  login_email: null,
  notification_email: null,
  tenant_id: null,
  tenant_name: null,
  user_assignees: [{ takemsgfor_id: null, takemesgfor_user: "" }],
  user_id: null,
  user_name: null,
  user_type: null,
  usertype_id: null,
};

export const initialRoleDetails = {
  tenant_id: null,
  role_id: null,
};

export const statusOption = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];

export const tenantOption = [{ label: "WB Television", value: 1 }];

export const roleOption = [
  { label: "Admin", value: 1 },
  { label: "Regular User", value: 2 },
  { label: "Read-Only", value: 3 },
];

import React from "react";
import ErrorBoundary from "../error-boundary/ErrorBoundary";
import Routes from "../route/Routes";

export default class MainContent extends React.Component {
  render() {
    return (
      <div>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </div>
    );
  }
}

import React from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import * as Constants from "../../constants/constants";
import PhoneLogService from "../../services/service";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BasicButton from "../SharedComponents/BasicButton/BasicButton";
import BasicCheckbox from "../SharedComponents/BasicCheckbox/BasicCheckbox";
import BasicTextField from "../SharedComponents/BasicTextField/BasicTextField";
import SelectField from "../SharedComponents/SelectField/SelectField";
import BasicLabel from "../SharedComponents/BasicLabel/BasicLabel";
import sizeLimits from "../../Common/SizeLimits.json";
import { withUserContext } from "../../contexts/UserContext";
import {
  initialUserDetails,
  initialRoleDetails,
  statusOption,
  roleOption,
  tenantOption,
} from "./config";
import { validateEmail, validateCharctersSpaceOnly } from "../../Common/Helper";
import "./NewUser.scss";

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userPostJson: JSON.parse(JSON.stringify(initialUserDetails)),
      postInitiated: false,
      isPosting: false,
      statusOption: [],
      tenantOption: [],
      canTakeMessageForUsers: [],
      roleOption: [],
      editMode: true,
      isFetchingTenants: false,
      isFetchingRoles: false,
    };
  }

  getDistinctArray = (arr) => {
    const distincitUserRoles = arr.filter(
      (ele, index, arr) =>
        index ===
        arr.findIndex((item) => item.takemsgfor_id === ele.takemsgfor_id)
    );
    return distincitUserRoles;
  };
  removeEmptyFeilds = (source, pro) => {
    return source?.filter((obj) => obj[pro] !== null);
  };
  validateBeforeSave = (userDetails) => {
    let canSubmit =
      userDetails?.full_name &&
      userDetails?.display_name &&
      userDetails?.tenant_id &&
      (!userDetails?.is_user_provisioner
        ? userDetails?.usertype_id && userDetails?.department_id
        : true) &&
      validateEmail(userDetails?.login_email) &&
      userDetails?.login_email &&
      userDetails?.login_email.length <= sizeLimits.emailCharacterLimit &&
      (userDetails?.notification_email !== null
        ? validateEmail(userDetails?.notification_email) &&
          userDetails?.notification_email &&
          userDetails?.notification_email.length <=
            sizeLimits?.emailCharacterLimit
        : true) &&
      this.getDistinctArray(userDetails?.user_assignees).length ===
        userDetails?.user_assignees.length;
    return canSubmit;
  };
  postUser = () => {
    this.setState({ postInitiated: true });

    // Remove empty element(default row)
    let roles = this.removeEmptyFeilds(
      this.state?.userPostJson?.user_assignees,
      "takemsgfor_id"
    );
    if (this.validateBeforeSave(this.state?.userPostJson)) {
      this.setState({ isPosting: true });
      this.setState(
        (prevState) => ({
          userPostJson: {
            ...prevState.userPostJson,
            user_assignees: roles,
          },
        }),
        () => {
          PhoneLogService.postDataParams(
            Constants.phoneLogServiceBaseUrl + "/postuserdetails",
            this.state?.userPostJson,
            this.props?.userContext?.active_tenant?.tenant_id
          ).then(
            (response) => {
              if (response.data.error) {
                this.props.handleMessageModalStatus("fail", true);
              } else {
                this.props.handleMessageModalStatus("success", true);
                this.props.callback(this.props?.setId);
              }
              this.setState({ isPosting: true, postInitiated: false });
            },
            (err) => {
              this.props.handleMessageModalStatus("fail", true);
              this.setState({ isPosting: true, postInitiated: false });
              // this.props?.handleSubmit("fail");
              // this.props.fieldChanged(true, "fail");
              //this.setState({ isPosting: false });
            }
          );
        }
      );
    }
  };

  getTenant = () => {
    this.setState({ isFetchingTenants: true });
    PhoneLogService.getData(
      Constants.phoneLogServiceBaseUrl +
        "/getallmasterdata?masterTable=tenants",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({
          allTenants: response.data,
          isFetchingTenants: false,
        });
      },
      (err) => {}
    );
  };

  getRoles = () => {
    this.setState({ isFetchingRoles: true });
    PhoneLogService.getData(
      Constants.phoneLogServiceBaseUrl +
        "/getallmasterdata?masterTable=usertypes",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState({ allRoles: response.data, isFetchingRoles: false });
      },
      (err) => {}
    );
  };

  getDepartments = () => {
    this.setState({ isFetchingRoles: true });
    PhoneLogService.getData(
      Constants.phoneLogServiceBaseUrl +
        "/getallmasterdata?masterTable=departments",
      this.props?.userContext?.active_tenant?.tenant_id
    ).then(
      (response) => {
        this.setState(
          {
            allDepartments: response.data,
            isFetchingRoles: false,
          },
          () => {
            if (this.props.mode === "edit") {
              this.handleDepartmentChange(
                "department_id",
                this.props?.userData?.department_id
              );
              // Add extra row as placeholder in edit mode
              this.handleAdd();
            }
          }
        );
      },
      (err) => {}
    );
  };

  componentDidMount() {
    if (this.props.mode === "edit") {
      this.setState(
        (prevState) => ({
          userPostJson: JSON.parse(JSON.stringify(this.props?.userData)),
        }),
        () => {
          this.setState({ editMode: false });
        }
      );
    }
    this.getTenant();
    this.getRoles();
    this.getDepartments();
  }

  handleAdd = (item) => {
    if (item?.takemsgfor_id || !item) {
      let roles = this.state?.userPostJson?.user_assignees;
      roles.push({
        takemsgfor_id: null,
        takemesgfor_user: "",
      });
      this.handleOnChange("user_assignees", roles);
    }
  };

  handleRemove = (deleteIndex) => {
    let roles = this.state?.userPostJson?.user_assignees;
    roles.splice(deleteIndex, 1);
    this.handleOnChange("user_assignees", roles);
  };

  handleOnChange = (field, value) => {
    this.setState(
      (prevState) => ({
        userPostJson: {
          ...prevState.userPostJson,
          [field]: value,
        },
      }),
      () => {
        this.props.handleMessageModalStatus("unsaved", true);
        if (field === "is_user_provisioner" && value === 1) {
          this.setState((prevState) => ({
            userPostJson: {
              ...prevState.userPostJson,
              ["user_assignees"]: [
                { takemsgfor_id: null, takemesgfor_user: null },
              ],
              ["usertype_id"]: null,
            },
          }));
        }
      }
    );
  };
  handleCanTakeMessageForChange = (field, value, index) => {
    let users = this.state?.userPostJson?.user_assignees;
    let newRole = this.state?.userPostJson?.user_assignees[index];
    newRole[field] = value;
    users.splice(index, 1, newRole);
    this.props.handleMessageModalStatus("unsaved", true);
    this.setState((prevState) => ({
      userPostJson: {
        ...prevState.userPostJson,
        user_assignees: users,
      },
    }));
  };

  handleDepartmentChange = (field, value, flag) => {
    // flag - if change is coming from department dropdown, clear can take message for feild
    this.props.handleMessageModalStatus("unsaved", true);
    this.setState(
      (prevState) => ({
        userPostJson: {
          ...prevState.userPostJson,
          [field]: value,
          ["user_assignees"]: flag
            ? [{ takemsgfor_id: null, takemesgfor_user: null }]
            : this.state.userPostJson.user_assignees,
        },
      }),
      () => {
        this.setCanTakeMessageForList();
      }
    );
  };

  setCanTakeMessageForList = () => {
    let user = this.props.allData.filter((user) => {
      return user.department_id === this.state.userPostJson.department_id;
    });
    this.setState({ canTakeMessageForUsers: user });
  };

  render() {
    let isLoading =
      this.props?.mode == "edit"
        ? this.state?.editMode ||
          this.state.isFetchingRoles ||
          this.state.isFetchingTenants
        : this.state.isFetchingRoles || this.state.isFetchingTenants;
    return (
      <div>
        {isLoading ? (
          <CircularProgress className="ContentLoader" />
        ) : (
          <MDBContainer className="newUserContainer">
            <MDBRow>
              <MDBCol md={6}></MDBCol>
              <MDBCol md={2}>
                <BasicCheckbox
                  id={"inactive"}
                  isMandatory={true}
                  label={"Inactive"}
                  value={this.state.userPostJson?.is_active || ""}
                  checked={!this.state.userPostJson?.is_active}
                  onChange={(e) =>
                    this.handleOnChange("is_active", e.target.checked ? 0 : 1)
                  }
                />
              </MDBCol>
              <MDBCol md={4}>
                <BasicCheckbox
                  id={"provisioner_user"}
                  isMandatory={true}
                  label={"Provisioner User"}
                  value={this.state.userPostJson?.is_user_provisioner || ""}
                  checked={this.state.userPostJson?.is_user_provisioner}
                  onChange={(e) =>
                    this.handleOnChange(
                      "is_user_provisioner",
                      e.target.checked ? 1 : 0
                    )
                  }
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md={12}>
                <BasicTextField
                  label="Full Name"
                  isMandatory={true}
                  value={this.state.userPostJson?.full_name || ""}
                  showMandatory={this.state.postInitiated}
                  onChange={(e) =>
                    validateCharctersSpaceOnly(e.target.value) &&
                    this.handleOnChange(
                      "full_name",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md={12}>
                <BasicTextField
                  label="Display Name"
                  isMandatory={true}
                  value={this.state.userPostJson?.display_name || ""}
                  showMandatory={this.state.postInitiated}
                  onChange={(e) =>
                    validateCharctersSpaceOnly(e.target.value) &&
                    this.handleOnChange(
                      "display_name",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md={6}>
                <BasicLabel text="Tenant" isMandatory={true} />
              </MDBCol>
              <MDBCol md={6}>
                <BasicLabel text="User Type" isMandatory={true} />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md={6}>
                <SelectField
                  id={"tenant-name"}
                  value={this.state?.userPostJson?.tenant_id || ""}
                  options={
                    this.state.allTenants?.map((item) => ({
                      label: item?.value,
                      value: item?.id,
                    })) || []
                  }
                  showMandatory={this.state.postInitiated}
                  onChange={(e) =>
                    this.handleOnChange("tenant_id", e.target.value)
                  }
                />
              </MDBCol>
              <MDBCol md={6}>
                <SelectField
                  id={"user-type"}
                  value={this.state?.userPostJson?.usertype_id || ""}
                  options={
                    this.state.allRoles?.map((item) => ({
                      label: item?.value,
                      value: item?.id,
                    })) || []
                  }
                  showMandatory={
                    this.state.postInitiated &&
                    !this.state.userPostJson?.is_user_provisioner
                  }
                  onChange={(e) =>
                    this.handleOnChange("usertype_id", e.target.value)
                  }
                  disabled={this.state.userPostJson?.is_user_provisioner}
                />
              </MDBCol>
            </MDBRow>
            {/* {this.getDistinctArray(this.state?.userPostJson?.user_assignees)
              .length !== this.state?.userPostJson?.user_assignees.length ? (
              <span className="errorText">
                For 1 tenant only 1 role can be assigned
              </span>
            ) : null} */}

            <MDBRow>
              <MDBCol md={12}>
                <BasicLabel text="Department" isMandatory={true} />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md={12}>
                <SelectField
                  id={"tenant-name"}
                  value={this.state?.userPostJson?.department_id || ""}
                  options={
                    this.state.allDepartments?.map((item) => ({
                      label: item?.value,
                      value: item?.id,
                      is_active: item.is_active,
                    })) || []
                  }
                  showMandatory={
                    this.state.postInitiated &&
                    !this.state.userPostJson?.is_user_provisioner
                  }
                  onChange={(e) =>
                    this.handleDepartmentChange(
                      "department_id",
                      e.target.value,
                      true
                    )
                  }
                />
              </MDBCol>
            </MDBRow>
            {/* {this.getDistinctArray(this.state?.userPostJson?.user_assignees)
              .length !== this.state?.userPostJson?.user_assignees.length ? (
              <span className="errorText">
                For 1 tenant only 1 role can be assigned
              </span>
            ) : null} */}

            <MDBRow>
              <MDBCol md={12}>
                <BasicTextField
                  label="Login Email"
                  isMandatory={true}
                  limit={sizeLimits.emailCharacterLimit}
                  limitWarning={`Exceeding ${sizeLimits.emailCharacterLimit} characters limit`}
                  value={this.state.userPostJson?.login_email || ""}
                  onChange={(e) =>
                    this.handleOnChange(
                      "login_email",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                  showMandatory={this.state.postInitiated}
                  inValidInput={"Invalid Email"}
                  fieldValid={
                    !validateEmail(this.state.userPostJson?.login_email) &&
                    this.state.userPostJson?.login_email
                  }
                />
                <span className="errorText">
                  {this.props?.allMails?.includes(
                    this.state.userPostJson?.login_email
                  ) && "Email already exists"}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md={12}>
                <BasicTextField
                  label="Notification Email"
                  isMandatory={true}
                  limit={sizeLimits.emailCharacterLimit}
                  limitWarning={`Exceeding ${sizeLimits.emailCharacterLimit} characters limit`}
                  value={this.state.userPostJson?.notification_email || ""}
                  onChange={(e) =>
                    this.handleOnChange(
                      "notification_email",
                      e.target.value.length > 0 ? e.target.value : null
                    )
                  }
                  showMandatory={this.state.postInitiated}
                  inValidInput={"Invalid Email"}
                  fieldValid={
                    !validateEmail(
                      this.state.userPostJson?.notification_email
                    ) && this.state.userPostJson?.notification_email
                  }
                />
                <span className="errorText">
                  {this.props?.allMails?.includes(
                    this.state.userPostJson?.notification_email
                  ) && "Email already exists"}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol md={4}>
                <BasicLabel text="Can Take message For" />
              </MDBCol>
            </MDBRow>
            {this.state?.userPostJson?.user_assignees?.map((item, index) => (
              <MDBRow>
                <MDBCol md={10}>
                  <SelectField
                    id={"tenant-role"}
                    value={item?.takemsgfor_id || ""}
                    disabled={
                      this.state?.userPostJson?.is_user_provisioner ||
                      this.state.canTakeMessageForUsers.length === 0
                    }
                    options={
                      this.state.canTakeMessageForUsers?.map((item) => ({
                        label: item?.full_name,
                        value: item?.user_id,
                      })) || []
                    }
                    onChange={(e) =>
                      this.handleCanTakeMessageForChange(
                        "takemsgfor_id",
                        e.target.value,
                        index
                      )
                    }
                  />
                </MDBCol>

                {this.state?.userPostJson?.user_assignees.length - 1 !==
                  index &&
                  !this.state?.userPostJson?.is_user_provisioner && (
                    <MDBCol md={1}>
                      <RemoveCircleIcon
                        onClick={() => this.handleRemove(index)}
                      />
                    </MDBCol>
                  )}

                {this.state?.userPostJson?.user_assignees.length - 1 ===
                  index &&
                  !this.state?.userPostJson?.is_user_provisioner && (
                    <MDBCol md={1}>
                      <AddCircleIcon onClick={() => this.handleAdd(item)} />
                    </MDBCol>
                  )}
              </MDBRow>
            ))}
            {this.getDistinctArray(this.state?.userPostJson?.user_assignees)
              .length !== this.state?.userPostJson?.user_assignees.length ? (
              <span className="errorText">Duplicate User Added</span>
            ) : null}

            <MDBRow className="btn-row">
              <MDBCol md={8}></MDBCol>
              <MDBCol md={2}>
                <BasicButton
                  variant="contained"
                  type="inline"
                  text={
                    this.state.isPosting ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : this.props?.mode == "new" ? (
                      "Save"
                    ) : (
                      "Save"
                    )
                  }
                  disabled={
                    this.state.userPostJson?.full_name?.trim().length === 0
                  }
                  onClick={this.postUser.bind(this)}
                />
              </MDBCol>
              <MDBCol md={2}>
                <BasicButton
                  type="inline"
                  variant="outlined"
                  text={"Cancel"}
                  onClick={this.props.onClose}
                  disabled={false}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}
      </div>
    );
  }
}

export default withUserContext(NewUser);

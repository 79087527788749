import { Subject } from "rxjs";

const contact$ = new Subject();
const lookup$ = new Subject();
const messageModal$ = new Subject();
const refreshPhoneLogs$ = new Subject();

export const AppService = {
  updateContactsData: (data) => contact$.next(data),
  getContactsData: () => contact$.asObservable(),
  updateLookupData: (type) => lookup$.next(type),
  getLookupData: () => lookup$.asObservable(),
  showMessageModal: (state) => messageModal$.next(state),
  getMessageModalState: () => messageModal$.asObservable(),
  refreshPhoneLogs: () => refreshPhoneLogs$.next(),
  getPhoneLogs: () => refreshPhoneLogs$.asObservable(),
};

import React from "react";
import TableComponent from "../../src/components/SharedComponents/Table";
import Modal from "../../src/components/SharedComponents/Modal/Modal";
import { userListConfig } from "../UserManagement/config";
import "../UserManagement/usermanagement.scss";
import BasicButton from "../../src/components/SharedComponents/BasicButton/BasicButton";
import { MDBRow, MDBCol } from "mdbreact";
import IconTextField from "../../src/components/SharedComponents/IconTextField/IconTextField";
import * as Constants from "../../src/constants/constants";
import PhoneLogService from "../../src/services/service";
import { withUserContext } from "../../src/contexts/UserContext";
class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allData: [],
      tableList: [],
      tableListSorted: [],
      a_z: false,
      page: 0,
      pageCount: 10,
      newUserModal: false,
      isFetchingUserDetails: false,
      mode: null,
      userId: null,
      totalCount: 0,
      searchTerm: null,
      is_enable: false,
      sortCount: 0,
      sortBy: "Performer",
      isSortByAsc: false,
    };
  }

  closeNewUserModal = () => {
    this.setState({ newUserModal: false, userId: null, mode: null });
  };

  componentDidMount = () => {
    this.getAllUsers();
  };

  handleSearch = (value) => {
    this.setState({
      searchTerm: value,
    });
    if (value.length === 1) {
      return false;
    }
    let tableListSorted;
    if (value.length > 1) {
      tableListSorted = this.state.allData?.filter((item) =>
        item.full_name?.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      tableListSorted = this.state.allData;
    }
    this.setState({
      tableListSorted,
    });
  };

  getAllUsers = () => {
    this.setState({ isFetchingUserDetails: true, searchTerm: null });
    PhoneLogService.getData(
      Constants.phoneLogServiceBaseUrl + "/getuserlist",
      this.props?.userContext?.user_profile?.tenant_id
    ).then(
      (response) => {
        this.setState({
          allData: response?.data,
          tableListSorted: response?.data,
          isFetchingUserDetails: false,
          is_enable: true,
        });
      },
      (err) => {
        console.log("Error in fetching set Details:", err);
        this.setState({
          isLoadingSetDetails: false,
          isFetchingUserDetails: false,
        });
      }
    );
  };

  handleEditUser = (val, item) => {
    this.setState({ mode: "edit", userId: val, newUserModal: true });
  };

  handleUserSelection = (item, node) => {
    if (node == "user_name") {
      this.setState({
        mode: "edit",
        userId: item?.user_id,
        newUserModal: true,
      });
    }
  };

  handleUserDetailsUpdate = () => {
    this.setState({ page: 1, pageCount: 10 });
    this.getAllUsers();
  };

  getPosition = (val) => {
    switch (val) {
      case "Full Name":
        return 0;
      case "Display name":
        return 1;
      case "Tenant":
        return 2;
      case "User Type":
        return 3;
      case "Email":
        return 4;
      case "Notification Email":
        return 5;
      case "Department":
        return 6;
      case "Status":
        return 7;
      default:
        return 7;
    }
  };
  getColumnName = (val) => {
    switch (val) {
      case "Full Name":
        return "full_name";
      case "Display name":
        return "display_name";
      case "Tenant":
        return "tenant_name";
      case "User Type":
        return "user_type";
      case "Email":
        return "login_email";
      case "Notification Email":
        return "notification_email";
      case "Department":
        return "department_name";
      case "Status":
        return "is_active_text";
      default:
        return "is_active_text";
    }
  };

  sortColumn = (val) => {
    let Position = this.getPosition(val);
    let col_name = this.getColumnName(val);
    let sortList = this.state.tableListSorted;
    if (this.state.a_z && userListConfig?.headings[Position]?.icon != "") {
      sortList.reverse();
      if (userListConfig?.headings[Position]?.icon == "sort-alpha-up") {
        userListConfig?.headings.map((item) => {
          item.icon = "";
        });
        userListConfig.headings[Position].icon = "sort-alpha-down";
      } else {
        userListConfig?.headings.map((item) => {
          item.icon = "";
        });
        userListConfig.headings[Position].icon = "sort-alpha-up";
      }
    } else {
      sortList.sort((a, b) =>
        a[col_name]?.toString().split(" ").join("").trim().toLowerCase() >
        b[col_name]?.toString().split(" ").join("").trim().toLowerCase()
          ? -1
          : 1
      );
      userListConfig?.headings.map((item) => {
        item.icon = "";
      });
      this.setState({ a_z: true });
      userListConfig.headings[Position].icon = "sort-alpha-up";
    }
    this.setState({
      tableListSorted: sortList,
    });
  };

  render() {
    let userPermissions = this.props?.userContext?.active_tenant?.permissions;
    return (
      <div>
        {userPermissions?.isManageUser ? (
          <div>
            {this.state.newUserModal && (
              <Modal
                open={this.state.newUserModal || false}
                modalName={"NewUser"}
                onClose={this.closeNewUserModal}
                userData={
                  this.state.mode == "edit"
                    ? this.state?.allData?.find(
                        (item) => item?.user_id === this.state.userId
                      )
                    : null
                }
                comfirmModalTitle={
                  this.state.mode == "edit" ? "Edit User" : "New User"
                }
                mode={this.state.mode}
                allData={this.state?.allData}
                callback={this.handleUserDetailsUpdate.bind(this)}
              />
            )}
            <div className="User-Management">
              <MDBRow className="heading-row">
                <MDBCol md={4}>
                  <h2>User Management</h2>
                </MDBCol>
                {this.state.is_enable && (
                  <MDBCol md={6} className="search">
                    <IconTextField
                      placeholder={"search users by name"}
                      value={this.state.searchTerm || ""}
                      onChange={(e) => this.handleSearch(e.target.value)}
                    />
                  </MDBCol>
                )}
                {this.state.is_enable && (
                  <MDBCol md={2} className="new-user-btn">
                    <BasicButton
                      className="FR MR1"
                      text={"New User"}
                      onClick={() =>
                        this.setState({ mode: "new", newUserModal: true })
                      }
                      type="inline"
                      variant="outlined"
                      //disabled={this.state?.history}
                      icon={"plus-circle"}
                    />
                  </MDBCol>
                )}
              </MDBRow>
              <div class="row">
                <div class="col-xs-12" id="usermanagement">
                  <TableComponent
                    list={this.state?.tableListSorted || []}
                    config={userListConfig}
                    tableMode={"light"}
                    isLoading={this.state.isFetchingUserDetails || false}
                    maxHeight={"450"}
                    editCallback={(val, item) => this.handleEditUser(val, item)}
                    sortCallback={this.sortColumn}
                    hyperLinkNavigationCallback={(item, node) =>
                      this.handleUserSelection(item, node)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <MDBRow className="User-Management">
            You do not have permissions to manage users
          </MDBRow>
        )}
      </div>
    );
  }
}
export default withUserContext(UserManagement);

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import LandingPage from "../home/LandingPage/LandingPage";
import UserManagement from "../../src/UserManagement/UserManagement";

export default withOktaAuth(
  class Routes extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <Switch>
          {/* <Route
            exact={true}
            path="/"
            render={(props) => <UserManagement {...props} />}
          /> */}
          <Route
            exact={true}
            path="/"
            render={(props) => <LandingPage {...props} />}
          />
          <Route
            exact={true}
            path="/userManagement"
            render={(props) => <UserManagement {...props} />}
          />
        </Switch>
      );
    }
  }
);
